import styled from 'styled-components';

import { colors, metrics } from 'styles';

export const Container = styled.div`
  max-width: ${metrics.container_max_width};
  background: ${colors.background_card};
  border-radius: 1.5rem;
  padding: 1.2rem;
  margin-top: 1.2rem;

  .item {
    height: 10rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center;

    img {
      width: 100%;
      max-width: 15rem;

      @media (min-width: 768px) {
        filter: grayscale(100%);
      }

      transition: 0.3s;

      &:hover {
        filter: grayscale(0);
      }
    }
  }

  .slick-next,
  .slick-prev {
    width: inherit;
    height: inherit;
  }

  .slick-prev:before,
  .slick-next:before {
    color: var(--primary-color);
    opacity: 1;
    font-size: 25px;
  }

  .slick-next {
    right: -35px;
  }

  .slick-track {
    width: 1px;
  }
`;
