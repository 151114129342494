import React from 'react';

import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Container } from './styles';

interface IMultiSliderBrandsProps {
  items: Array<{
    imagemUrl: string;
    redirecionarUrl: string;
  }>;
}

const MultiSliderBrands: React.FC<IMultiSliderBrandsProps> = ({ items }) => {
  const settings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Slider {...settings}>
        {items.map(item => (
          // <div  onClick={() => {}}>
          <a
            href={`/marca/${item.redirecionarUrl}`}
            title={item.redirecionarUrl}
            className="item"
            key={item.imagemUrl}
          >
            <img
              loading="lazy"
              src={item.imagemUrl}
              alt={item.redirecionarUrl}
            />
          </a>
          // </div>
        ))}
      </Slider>
    </Container>
  );
};

export default MultiSliderBrands;
